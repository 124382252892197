import React, { useEffect, useState } from 'react';
import Loader from '../components/Loader/Loader';
import { REDTRK_URL } from '../constants';
import MoreInfo from './MoreInfo/MoreInfo';
import './Rewards.css';
import RewardsBody from './RewardsBody/RewardsBody';
import cash100 from '../assets/PromoImages/cash100_20221.jpeg';
import cash250 from '../assets/PromoImages/cash250_2022.jpeg';
import cash500 from '../assets/PromoImages/cash500_2022.png';
import cash750 from '../assets/PromoImages/cash750_2022.webp';
import cash750v2 from '../assets/PromoImages/ps_cashapp_750.png';
import cash1000 from '../assets/PromoImages/cash1000_2022.png';
import walmart100 from '../assets/PromoImages/walmart100.png';
import walmart500 from '../assets/PromoImages/walmart500genericwalmart_bokeh.png';
import walmart750 from '../assets/PromoImages/walmart750.png';
import amazon100 from '../assets/PromoImages/amazon100_lg.png';
import chiptole100 from '../assets/PromoImages/chipotle100new_v2.png';
import kroger500 from '../assets/PromoImages/kroger500.png';
import burgerKing100 from '../assets/PromoImages/burgerking100.png';
import sephora750 from '../assets/PromoImages/sephora750.png';
import pizzahut1000 from '../assets/PromoImages/pizzahut1000.png';
import shein750 from '../assets/PromoImages/shein750us.png';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import standartBackgr1 from '../assets/backgrAI1-dark.png';
import standartBackgr2 from '../assets/backgrAI1-dark2.webp';
import standartBackgr3 from '../assets/backgr6_dard.jpg';
import standartBackgr4 from '../assets/backgr64_dark.jpg';
import fortniteBackgr1 from '../assets/fortniteBackgr1.png';
import robloxBackgr1 from '../assets/RobloxBackgr1.png';
import ps5fnpic from '../assets/PromoImages/ps5fn.jpeg';
import ps5fnpic2 from '../assets/PromoImages/ps5fn2.jpeg.png';
import robloxsamsungs22 from '../assets/PromoImages/RobloxS22Blue - Copy.png';
import iphonepic from '../assets/PromoImages/iphone14-removebg-preview.png';
import iphone15pic from '../assets/PromoImages/iphon15.png'
import venmo750pic from '../assets/PromoImages/venmo750_lg.png'
import ups750pic from '../assets/PromoImages/ups750.png'
import fedex100pix from '../assets/PromoImages/fedex100.png'
import amazonprimePic from '../assets/PromoImages/amazonprime112.jpg';
import temupic from '../assets/PromoImages/temu750.png';
import paypal750 from '../assets/PromoImages/paypal750disclaimer.png';
import capitalone1000pic from '../assets/PromoImages/capitalone1000.png';
import mastercard1000pic from '../assets/PromoImages/mastercard1000.png';
import amazon100halloweenpic from '../assets/PromoImages/amazon100hall.png';
import visa1000halloweenpic from '../assets/PromoImages/visa1000hall.png';
import fashionnova750halloweenpic from '../assets/PromoImages/fashionnova750halloween.png';
import target100halloweenpic from '../assets/PromoImages/target100_halloween_2.png';
import shein750halloweenpic from '../assets/PromoImages/shein750halloween.png';
import halloweenBackgr1 from '../assets/hallowBackgr1.png';
import halloweenBackgr2 from '../assets/hallowBackgr2.png';
import earning1000pic from '../assets/PromoImages/phonescreen1000.png';
import amazon750pic from '../assets/PromoImages/amazon750disclaimer.png';
import ps5fc24pic from '../assets/PromoImages/ps5-fc24.png';
import fc24backgr from '../assets/fc24backgr3.png';
import fc24backgr2 from '../assets/fc24backgr1.png';
import HeaderSamples from '../components/HeaderSamples/HeaderSamples';

const Rewards = ({ pageName }) => {
  const [title, setTitle] = useState('Gift Card Giveaway! Register to Continue!');
  const [headline, setHeadline] = useState('');
  const [picURL, setpicURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [backGr1, setBackgr1] = useState('none');
  const [backGr2, setBackgr2] = useState('none');
  const [textColorDesktop, setTextColorDesktop] = useState('rgb(44, 78, 146)');
  const [textColorMobile, setTextColorMobile] = useState('white');
  const [extraHeader, setExtraHeader] = useState(false)
  const [campaignName, setCampaignName] = useState('US $750 Cash App')

  useEffect(() => {
    if (pageName.includes('Cash') && pageName.includes('100')) {
      setHeadline('$100 in Your Cash Account*');
      setpicURL(cash100);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
      setCampaignName('US $100 Cash App')
    } else if (pageName.includes('Cash') && pageName.includes('250')) {
      setHeadline('$250 in Your Cash Account*');
      setpicURL(cash250);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('Cash') && pageName.includes('500')) {
      setHeadline('$500 in Your Cash Account*');
      setpicURL(cash500);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('Cash') && pageName.includes('750') && !pageName.includes('v2') ) {
      setHeadline('$750 in Your Cash Account*');
      setpicURL(cash750);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    }  else if (pageName.includes('v2') && pageName.includes('Cash') && pageName.includes('750') ) {
      setHeadline('$750 in Your Cash Account*');
      setpicURL(cash750v2);
      setBackgr1(standartBackgr3);
      setBackgr2(standartBackgr4);
    }  else if (pageName.includes('Cash') && pageName.includes('10-0')) {
      setHeadline('$1000 in Your Cash Account*');
      setpicURL(cash1000);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    }else if (pageName.includes('Walmart') && pageName.includes('100')) {
      setHeadline('$100 Walmart Gift Card*');
      setpicURL(walmart100);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('Walmart') && pageName.includes('500')) {
      setHeadline('$500 Walmart Gift Card*');
      setpicURL(walmart500);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('Walmart') && pageName.includes('750')) {
      setHeadline('$750 Walmart Gift Card*');
      setpicURL(walmart750);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('Amazon') && pageName.includes('100')) {
      setHeadline('$100 Amazon Gift Card*');
      setpicURL(amazon100);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('Chipotle') && pageName.includes('100')) {
      setHeadline('$100 Chipotle Gift Card*');
      setpicURL(chiptole100);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('Kroger') && pageName.includes('500')) {
      setHeadline('$500 Kroger Gift Card*');
      setpicURL(kroger500);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('Burger King') && pageName.includes('100')) {
      setHeadline('$100 Burger King Gift Card*');
      setpicURL(burgerKing100);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
      setCampaignName('US Burger King $100')
    } else if (pageName.includes('Sephora') && pageName.includes('750')) {
      setHeadline('$750 Sephora Gift Card*');
      setpicURL(sephora750);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('Pizza Hut') && pageName.includes('1000')) {
      setHeadline('$1000 Pizza Gift Card*');
      setpicURL(pizzahut1000);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('Shein') && pageName.includes('750')) {
      setHeadline('$750 Shein Gift Card*');
      setpicURL(shein750);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('fnps5') && pageName.includes('rewards')) {
      setHeadline(`PlayStation 5*`);
      setpicURL(ps5fnpic);
      setBackgr1(fortniteBackgr1);
      setTextColorMobile('rgb(44, 78, 146)');
      // setBackgr2(fortniteBackgr3);
    } else if (pageName.includes('fn2ps5') && pageName.includes('rewards')) {
      setHeadline(`PlayStation 5*`);
      setpicURL(ps5fnpic2);
      setBackgr1(fortniteBackgr1);
      setTextColorMobile('rgb(44, 78, 146)');

      // setBackgr2(fortniteBackgr2);
    } else if (pageName.includes('roblox') && pageName.includes('rewards') && pageName.includes('samsungs221')) {
      setHeadline(`Samsung S22*`);
      setpicURL(robloxsamsungs22);
      setBackgr1(robloxBackgr1);
      setTextColorMobile('rgb(44, 78, 146)');

      // setBackgr2(robloxBackgrdark);
    } else if (pageName.includes('iphone14') && pageName.includes('rewards')) {
      setHeadline(`the iPhone 14*`);
      setpicURL(iphonepic);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);

    } else if (pageName.includes('iphone15') && pageName.includes('rewards')) {
      setHeadline(`the New iPhone 15*`);
      setpicURL(iphone15pic);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);

    }  else if (pageName.includes('venmo') && pageName.includes('750')) {
      setHeadline(`$750 in your Venmo*`);
      setpicURL(venmo750pic);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
  
    } else if (pageName.includes('ups') && pageName.includes('750')) {
      setHeadline(`$750 for UPS Shipping*`);
      setpicURL(ups750pic);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('fedex') && pageName.includes('100')) {
      setHeadline(`$100 for FedEx Shipping*`);
      setpicURL(fedex100pix);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('amazon') && pageName.includes('prime')) {
      setHeadline(`A Year of Amazon Prime*`);
      setpicURL(amazonprimePic);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('temu') && pageName.includes('750')) {
      setHeadline(`$750 Temu Gift Card*`);
      setpicURL(temupic);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    }  else if (pageName.includes('paypal') && pageName.includes('750')) {
      setHeadline(`$750 Paypal Gift Card*`);
      setpicURL(paypal750);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    }  else if (pageName.includes('capitaloneplatinum') && pageName.includes('1000')) {
      setHeadline(`$1000 Capital One Platinum Card*`);
      setpicURL(capitalone1000pic);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    }  else if (pageName.includes('mastercardplatinum') && pageName.includes('1000')) {
      setHeadline(`$1000 Mastercard Platinum Card*`);
      setpicURL(mastercard1000pic);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } 
    
    else if (pageName.includes('amazon') && pageName.includes('100') && pageName.includes('halloween')) {
      setHeadline(`$100 Amazon Gift Card*`);
      setpicURL(amazon100halloweenpic);
      setBackgr1(halloweenBackgr2);
      setBackgr2(halloweenBackgr1);
    } else if (pageName.includes('visa') && pageName.includes('1000') && pageName.includes('halloween')) {
      setHeadline(`$1000 Visa Gift Card*`);
      setpicURL(visa1000halloweenpic);
      setBackgr1(halloweenBackgr2);
      setBackgr2(halloweenBackgr1);
    } else if (pageName.includes('fashionnova') && pageName.includes('750') && pageName.includes('halloween')) {
      setHeadline(`$750 Fashion Nova Gift Card*`);
      setpicURL(fashionnova750halloweenpic);
      setBackgr1(halloweenBackgr2);
      setBackgr2(halloweenBackgr1);
    } else if (pageName.includes('target') && pageName.includes('100') && pageName.includes('halloween')) {
      setHeadline(`$100 Target Gift Card*`);
      setpicURL(target100halloweenpic);
      setBackgr1(halloweenBackgr2);
      setBackgr2(halloweenBackgr1);
    } else if (pageName.includes('shein') && pageName.includes('750') && pageName.includes('halloween')) {
      setHeadline(`$750 Shein Gift Card*`);
      setpicURL(shein750halloweenpic);
      setBackgr1(halloweenBackgr2);
      setBackgr2(halloweenBackgr1);
    } 
    else if (pageName.includes('earning') && pageName.includes('1000')) {
      setHeadline(`Earn up to $1000 This Week*`);
      setpicURL(earning1000pic);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
    } else if (pageName.includes('amazon') && pageName.includes('750') && pageName.includes('productreview') ) {
      setHeadline(`$750 in your Amazon Account*`);
      setpicURL(amazon750pic);
      setBackgr1(standartBackgr1);
      setBackgr2(standartBackgr2);
      setExtraHeader('limitedbonus')
    } else if (pageName.includes('ps5') && pageName.includes('fc24') ) {
      setHeadline(`a PS5*`);
      setpicURL(ps5fc24pic);
      setBackgr1(fc24backgr2);
      setBackgr2(fc24backgr2);
    } 
  }, []);

  // useEffect(() => {
  //   const existingUser = window.localStorage.getItem('S&R');

  //   if (existingUser) {
  //     setLoading(true);
  //     const doLoading = setTimeout(() => {
  //       window.location.replace(
  //         `${REDTRK_URL}?email=${existingUser}&sub2=${existingUser}&sub3=repeating_user`
  //       );
  //     }, 700);
  //     return () => clearTimeout(doLoading);
  //   }
  // }, []);

  return (
    <div>
      <Header />
      <div className="main-container">
        {loading ? (
          <div className="loader-div">
            <h2 id="loading-text">Checking if you qualify...</h2>
            <br />
            <br />
            <Loader width="15.5em" height="12.23em" style={{ border: '1px solid black' }} />
          </div>
        ) : (
          <RewardsBody
            title={title}
            headline={headline}
            picURL={picURL}
            backgroundPic1={backGr1}
            backgroundPic2={backGr2}
            textColorDesktop={textColorDesktop}
            textColorMobile={textColorMobile}
            extraHeader={extraHeader}
            campaignName={campaignName}
          />
        )}

        <MoreInfo />
      </div>
      <Footer />
    </div>
  );
};

export default Rewards;
